<!--
21-12-2020:
############################
04-06-2021
Se agrego Facebook Pixel y Google Analytic
10-04-2022
Se agrego <lst-carousel> p/ soportar click p/ seleccionar cod.
30-05-2022
Se corrigio error que faltaba evParser(prec_m....)
03-06-2022
Se mejoro en sendPed() para que controle cuando se acaba el tiempo de la session.
13-09-2022
Se agrego soporte p/ stk_prs.web_coef_calc = 'G' y 'U'.
03-10-2022
Se corrigio error que faltaba  
showPrecBulM () {
  return this.dicPlu.stat_m && evParser(this.dicPlu.precbul_m, { type: 'evF' }) < evParser(this.dicPlu.prec_m, { type: 'evF' })
},
05-11-2022
Se agrego vmApp.howCreateUserMsg p/ Premier
#
31-08-2023
A paritr de FC38 comenzo a dar el siguiente error al intentar compilar:
(node:62314) [DEP0111] DeprecationWarning: Access to process.binding('http_parser') is deprecated.
Para solucionarlo hicimos:
  a) mv tienda  tienda.20230831 (De dejo copia)
  b) cp -r rpr-evsa-sitio tienda (Se tomo como base el sitio web de evsa)
  c) Se reemplazarom todos los src de 'sitio' por 'tienda'
  d) Hubo que adaptar el uso de:
     form=evForm(modelos) 
     por:
        const form = new EvForm({})
        form.initAll({modelos: modelos})
#
12-09-2023
Se agrego cycle al carousel y click p/ item.grpgrpdep
#
14-11-2023
Se agrego nuevo parametro: appInicioFileNi
#
25-01-2024
Se agrego parametros para "sustituir".
-->
<template>
  <v-app>
    <evu-modal ref="evModal" />


    <v-dialog 
      transition="dialog-bottom-transition"
      max-width="600"
      :value="!!appInicioFileNi"
    >
        <template v-slot:default="dialog">
          <v-card XXXdark>
            <v-img
              class="mx-auto"
              :src="`/drive/file/index/0/${appInicioFileNi}`"
            />            
            <v-card-actions class="justify-end">
              <v-btn
                color="primary"
                text
                @click="dialog.value = false"
              >continuar</v-btn>
            </v-card-actions>
          </v-card>
        </template>    
    </v-dialog>


    <v-navigation-drawer
      app
      v-model="drawerLeft"
      left
      clipped
      color="grey lighten-5"
    >
      <v-container class="text-center">
        <v-row>
          <v-btn
            icon
            @click="$router.go(-1)"
            class="mr-auto"
          >
            <v-icon class="primary--text">mdi-arrow-left</v-icon>
          </v-btn>

          <v-btn
            icon
            @click="drawerLeft=false"
            class="ml-auto"
          >
            <v-icon class="primary--text">mdi-close</v-icon>
          </v-btn>

        </v-row>

        <v-btn
          text
          small
          color="primary"
          to="/"
        >Inicio</v-btn>

      </v-container>
      <v-divider></v-divider>

      <plu-menu v-show="drawerLeftShow === 'plu-menu'"></plu-menu>

      <categoria-menu v-show="drawerLeftShow === 'categoria-menu'"></categoria-menu>

    </v-navigation-drawer>

    <v-navigation-drawer
      app
      v-model="drawerRight"
      right
      temporary
      width="400"
    >
      <checkout></checkout>
    </v-navigation-drawer>

    <appbar-movil
      :appTitle="appTitle"
      :appUser="appUser"
      @clickDrawerLeft="drawerLeft = !drawerLeft"
      @clickDrawerRight="drawerRight = !drawerRight"
      v-if="$vuetify.breakpoint.smAndDown"
    >
    </appbar-movil>

    <appbar-simple
      :appTitle="appTitle"
      :appUser="appUser"
      @clickDrawerLeft="drawerLeft = !drawerLeft"
      @clickDrawerRight="drawerRight = !drawerRight"
      v-if="!$vuetify.breakpoint.smAndDown && appBarConf.fmt === 'simple'"
    >
    </appbar-simple>

    <appbar-doble
      :appTitle="appTitle"
      :appUser="appUser"
      @clickDrawerLeft="drawerLeft = !drawerLeft"
      @clickDrawerRight="drawerRight = !drawerRight"
      v-if="!$vuetify.breakpoint.smAndDown && appBarConf.fmt === 'doble'"
    >
    </appbar-doble>

    <v-main>
      <router-view></router-view>
    </v-main>
    <app-footer :footer="footer">
    </app-footer>
  </v-app>
</template>


<script>
import evuModal from '@/evlib/evu-components/evu-modal'
import appbarMovil from '@/components/appbar-movil'
import appbarSimple from '@/components/appbar-simple'
import appbarDoble from '@/components/appbar-doble'
import appFooter from '@/components/app-footer'
import categoriaMenu from "@/components/categoria-menu"
import pluMenu from "@/components/plu-menu"
import checkout from "@/components/checkout"

import { evAxio } from '@/evlib/utils/ev-axios'
import { evSessionInit, evSessionWrite } from '@/evlib/utils/ev-session'

import { setOptions, bootstrap } from 'vue-gtag'

export default {
  name: 'App',
  components: {
    evuModal,
    appbarMovil,
    appbarSimple,
    appbarDoble,
    appFooter,
    categoriaMenu,
    pluMenu,
    checkout

  },
  provide () {
    return {
      vmApp: this
    }
  },
  data () {
    return {
      appTitle: 'appTitle',
      appUser: {}, // Ver: ev-session.js
      appBarConf: {
        fmt: 'simple', // 'simple', 'doble'

        height: 70,
        extensionHeight: 0, // 25

        color: '#FFFFFF', // '#232F3E', // '#232F3E','#FFFFFF'
        extensionColor: '#BDBDBD', // '#ED2224', '#1B2530',

        dark: false,
        extensionDark: true
      },
      appFooterConf: {
        tel: '',
        sucursales: [], // [{title, dir, loc, tel}]
        sociales: [], // [{icon, url}]
        menu: [], // [{icon, title, subtitle}]
        afipHref: '', // 'http://qr.afip.gob.ar/?qr=pLp_Jese8yad_8tTqI1JBg,,',
        afipSrc: '', // 'http://www.afip.gob.ar/images/f960/DATAWEB.jpg',


      },
      appTextConf: {
        advPrsCant: 'En los productos pesables la cantidad de Kg de cada pieza es aproximada. La cantidad de Kg exacta recien la sabremos al preparar el pedido y emitir la factura correspondiente.'
      },
      appLstCarousel: [], // [{file_ni: 2, cod: 334}, ...]
      
      appInicioFileNi: null, // file_ni de la imagen a mostrar en el dialogo de inicio

      // 05-11-2022 P/ Premier
      // howCreateUserMsg = "Comuníquese al Tel. 0381-433-0895 para que le creemos una cuenta."
      howCreateUserMsg: '',

      drawerLeftShow: '', // 'plu-main', 'grpgrpdep-menu'
      drawerLeft: false, // null, // false,
      drawerRight: false,

      footer: '&copy; Creado por Electrónica Valle S.A.',

      // 01-10-2022
      // Se paso al store
      // tiendaConf: {
      //   fpg_cta: 'N',  // 'S' => que el precio depende del cliente que este logueado
      //   rows: []
      // }

      sustMsg: '',
      sustOptions: [],
    }
  },

  computed: {
    tiendaConf () {
      return this.$store.state.tiendaConf
    },
    user () {
      return this.appUser.user
    },
  },
  watch: {
    user (n, o) {
      if (!n && !!o) {
        // Me aseguro de borrar el carrito cuando se desloguea
        this.$store.dispatch('cmdVaciarCarrito')
      }
      if (o !== undefined) {
        // Me aseguro de que muestre los precios que corresponda segun el cliente
        this.getTiendaConf()
      }
    }
  },

  methods: {
    // Lee Titulo y colores de la tienda
    getAppConf () {
      evAxio(
        {
          vm: this,
          url: '/gra/login/api/getAppConf',
          params: { 'conf_js': '/ccd_cliente/tienda' }
        }
      )
        .then((data) => {
          // 05-11-2022
          // Se agrego p/ Premier p/ que no permita crear cuentas nuevas.
          // Ver: ev-login.vue
          this.howCreateUserMsg = data.appConf.howCreateUserMsg || ''

          this.appTitle = data.appConf.appTitle
          if (data.appConf.colores) {
            this.setColores(data.appConf.colores)
          }
          if (data.appConf.appBarConf) {
            this.appBarConf = { ...this.appBarConf, ...data.appConf.appBarConf }
          }
          if (data.appConf.appFooterConf) {
            this.appFooterConf = { ...this.appFooterConf, ...data.appConf.appFooterConf }
          }

          if (data.appConf.appTextConf) {
            this.appTextConf = { ...this.appTextConf, ...data.appConf.appTextConf }
          }

          if (data.appConf.appLstCarousel) {
            this.appLstCarousel = data.appConf.appLstCarousel
          }

          if (data.appConf.appInicioFileNi) {
            this.appInicioFileNi = data.appConf.appInicioFileNi
          }

          if (data.appConf.sustMsg) {
            this.sustMsg = data.appConf.sustMsg
            this.sustOptions = data.appConf.sustOptions
          }

          if (data.appConf.analyticID) {
            this.enableGtagPlugin(data.appConf.analyticID)
          }
          if (data.appConf.fbqID) {
            this.enableFbq(data.appConf.fbqID)
          }


        })
    },
    setColores (colores) {
      for (const id in colores) {
        this.$vuetify.theme.themes.light[id] = colores[id]
        this.$vuetify.theme.themes.dark[id] = colores[id]
      }
    },

    getAppUser () {
      evAxio({ vm: this, url: '/ccd_cliente/login/api/getAppUser' })
        .then((data) => {
          evSessionWrite(this, data.appUser)
        })
    },

    // Lee configuracion para pantalla de incio
    getTiendaConf () {
      this.$store.dispatch('getTiendaConf', { vm: this })
    },

    // Se carga al principio la info del pedido en transito
    // =====================================================================
    initPedido () {
      const dicPedido = JSON.parse(
        localStorage.getItem("ccd_cliente-tienda-pedido")
      )

      this.$store.commit("SET_DIC_PEDIDO", dicPedido || {})

      // eslint-disable-next-line no-console
      // console.log(['App.vue: initPedido() dicPedido=', dicPedido])

      if (dicPedido) {
        if (dicPedido.ped_ni) {
          // Redirijo p/ que finalice el pedido
          this.$router.replace('/fin-confirmacion')

        } else if (dicPedido.renglones && dicPedido.renglones.length) {

          this.$store.dispatch('refreshRenglones', { vm: this, renglones: dicPedido.renglones })
        }
      }
    },

    // analyticID: 
    // "G-C412Q0D19W": electronicavalle.com.ar 
    // "UA-197467856-1": alem500.com.ar
    enableGtagPlugin (analyticID) {
      // console.log([' enableGtagPlugin(): analyticID=NOOOO', analyticID])

      setOptions({
        config: { id: analyticID }
      })

      bootstrap()
      /*
      bootstrap().then(gtag => {
        console.log(['enableGtagPlugin.bootstrap.then() gtag=', gtag])
        // all done!
      })
      */
    },


    // Facebook Pixel Code
    // Alem500 fpqID='4202350853161830'     
    enableFbq (fbqID) {
      // console.log(['enableFbq(): fbqID=', fbqID])
      window.fbq('init', fbqID)
      window.fbq('track', 'PageView')
    }

  },
  created () {
    // Inicializa Session
    // sessionType:
    //   0= No usa token. El servidor es el encargado de llevar la session
    //   1= Usa $root.evToken y vmApp.appUser, pero no guarda en localStorage.
    //   2= Idem a 1, pero guarda session en localStorage.
    evSessionInit({
      vmApp: this,
      sessionType: 0,
      sessionId: 'ccd_cliente',
      userLabel: 'Correo Electrónico o Cod. de Cliente',
      canResetPasswd: true,
      canCreateUser: true
    })

  },

  mounted () {
    // eslint-disable-next-line no-console
    // console.log(['App.vue.mounted()'])
    this.getAppConf()

    this.getAppUser()

    this.getTiendaConf()

    this.$store.dispatch("initOptionsReparCod", this)

    this.$store.dispatch("initOptionsGrpgrpdep", this)
    this.$store.dispatch("initOptionsGrpdep", this)
    this.$store.dispatch("initOptionsDep", this)

    // Me aseguro que ya esten cargados opciones de presentacion antes de inicializar el pedido
    this.$store.dispatch("initOptionsPrs", this)
      .then(this.initPedido)

  },

}
</script>
